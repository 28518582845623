<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    title="对接表单参数"
    width="800px"
    top="3vh"
    @close="$reset('form')">
    <div>
      <div style="width: 100%;float: left">
        <v-form-render v-if="formRenderVisible" :form-json="model.formParams" :form-data="formData"
                       :option-data="optionData" ref="vFormRef">
        </v-form-render>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false;">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        formData: {},
        optionData: {},
        dialogVisible: false,
        submitLoading: false,
        formRenderVisible: false,
        model: {
          id: "",
          name: "",
          integrationKey: "",
          params: [],
          formParams: {},
          doc: {
            url: "",
            filename: "",
          },
          accessDoc: {
            url: "",
            filename: "",
          },
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("integrations/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
            this.model.formParams = data.formParams;
            this.formRenderVisible = true;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
