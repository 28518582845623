<template>
  <div>
    <vm-table ref="vmTable" :filter.sync="filter" url="integrations">
      <template slot="adSearch">
        <vm-search label="对接名称">
          <el-input v-model.trim="filter.name" placeholder="对接名称" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">+ 新增</el-button>
      </template>
      <el-table-column prop="name" label="对接名称" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column prop="integrationKey" label="对接key" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column label="对接文档" show-overflow-tooltip>
        <template v-if="scope.row.doc && scope.row.doc.url" slot-scope="scope">
          <el-link type="primary" style="font-size: 12px;" @click="downloadDoc(scope.row.doc.url)">{{ scope.row.doc.filename }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="平台接入文档" show-overflow-tooltip>
        <template v-if="scope.row.accessDoc && scope.row.accessDoc.url" slot-scope="scope">
          <el-link type="primary" style="font-size: 12px;" @click="downloadDoc(scope.row.accessDoc.url)">{{ scope.row.accessDoc.filename }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column prop="showInHome" label="首页展示" align="center" width="100">
        <template v-slot="scope">
          <el-tag v-if="scope.row.showInHome" type="success">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="editAuth" label="操作" align="center" fixed="right" width="270">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.itemPage.open(scope.row.id)">对接企业</el-button>
          <el-button type="text" @click="$refs.logPage.open(scope.row.integrationKey)">请求日志</el-button>
          <el-button type="text" @click="$refs.editPage.open(scope.row.id)">编辑</el-button>
          <el-button type="text" v-if="false" @click="$refs.formParamsPage.open(scope.row.id)">对接表单</el-button>
          <el-button type="text" @click="$refs.formSetParams.open(scope.row.id)">对接表单</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <form-params-page ref="formParamsPage"></form-params-page>
    <form-set-params ref="formSetParams"></form-set-params>
    <item-page ref="itemPage"></item-page>
    <log-page ref="logPage"></log-page>
  </div>
</template>
<script>
import EditPage from "./Edit.vue";
import FormParamsPage from "./FormParams.vue";
import ItemPage from "./Item.vue";
import LogPage from "./Log.vue";
import FormSetParams from "@/views/integration/FormSetParams";

export default {
  components: { FormSetParams, EditPage, FormParamsPage, ItemPage, LogPage },
  data() {
    return {
      editAuth: this.$auth(201),
      filter: {
        name: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      this.$confirm(`确定删除${row.name}吗?`, "提示", { type: "warning" }).then(() => {
        this.$http.delete("integrations", row.id).then(() => {
          this.getList(-1);
          this.$message.success("删除成功");
        });
      });
    },
    downloadDoc(url) {
      const isHttps = "https:" == document.location.protocol ? true : false;
      if (isHttps) {
        window.open(url.replace("http:", "https:"));
      } else {
        window.open(url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 0 10px;
}
</style>
