<template>
  <el-dialog v-if="dialogVisible" v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible"
    :title="'接口调用日志' + filter.integrationKey" width="1500px" top="2vh" @close="$reset('form')">
    <vm-table ref="vmTable" :filter.sync="filter" :url="url">
      <template slot="adSearch">
        <vm-search label="开始时间">
          <el-date-picker v-model="filter.startTime" style="width: 185px" type="date" value-format="yyyy-MM-dd 00:00:00">
          </el-date-picker>
        </vm-search>
        <vm-search label="结束时间">
          <el-date-picker v-model="filter.endTime" style="width: 185px" type="date" value-format="yyyy-MM-dd 23:59:59">
          </el-date-picker>
        </vm-search>
        <vm-search label="电梯标识">
          <el-input v-model.trim="filter.elevatorKey" clearable></el-input>
        </vm-search>
        <vm-search label="接口名称">
          <el-input v-model.trim="filter.apiName" clearable></el-input>
        </vm-search>
        <vm-search label="其他标签">
          <el-input v-model.trim="filter.otherTag" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="elevatorKey" label="标签" width="200px">
        <template slot-scope="scope">
          <div>请求时间：{{ scope.row.ts }}</div>
          <div>电梯标识：{{ scope.row.elevatorKey || "无" }}</div>
          <div>接口名称：{{ scope.row.apiName || "无" }}</div>
          <div>其他标签：{{ scope.row.otherTag || "无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="数据">
        <template slot-scope="scope">
          <div>
            <div style="font-weight: bolder">URL</div>
            <span v-if="scope.row.status !== 200" style="font-weight: bolder; ">{{ scope.row.result === null ? "【请求中】" : ("【"
              + scope.row.status + "】") }}</span>
            <span style="font-weight: bolder;color: #0d65ab">{{ scope.row.method }}</span> {{ scope.row.url }}
          </div>
          <div>
            <div style="font-weight: bolder;margin-top: 10px">请求数据</div>
            <div>{{ scope.row.request }}</div>
          </div>
          <div>
            <div style="font-weight: bolder;margin-top: 10px">回复数据</div>
            <div>{{ scope.row.response }}</div>
          </div>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      url: window.config.integrationUrl + "integrationLog/logs",
      filter: {
        startTime: "",
        endTime: "",
        elevatorKey: "",
        apiName: "",
        otherTag: "",
      },
    };
  },
  methods: {
    open(integrationKey) {
      this.dialogVisible = true;
      this.filter.integrationKey = integrationKey;
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.$refs.vmTable.clear();
      this.$refs.vmTable.getList(pageNum);
    },
  },
};
</script>

<style lang="scss" scoped></style>
