<template>
  <el-dialog
    v-el-drag-dialog
    class="small-padding"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.name+'-对接企业'"
    width="1000px"
    top="3vh">
    <div v-loading="contentLoading">
      <el-button type="primary" @click="addItem">添加</el-button>
      <div style="margin-top: 10px;height: 60vh;overflow: auto">
        <el-form
          class="form"
          label-width="100px">
          <div v-for="(item,itemIndex) in items" :key="item.id||item.vueKey"
               style="width: 45%;display: flex;align-items: center;float: left;margin-right: 5%">
            <div class="item">
              <el-form-item label="企业名称">
                <vm-select-input :value="item.companyName" @select="$refs.selectCom.open(null,item)"></vm-select-input>
              </el-form-item>
              <el-form-item :label="$l('company.tenant','租户名称')" prop="tenantName">
                <el-input
                  v-model="item.tenantName"
                  :placeholder="$l('workOrder.pleaseSelect','请选择公司')"
                  readonly
                  disabled></el-input>
              </el-form-item>
              <el-form-item
                v-for="(value,key) in item.params"
                :key="key"
                :label="key">
                <el-input v-model="item.params[key]"></el-input>
              </el-form-item>

              <div style="width: 100%;text-align: right">
                <el-button
                  type="primary"
                  @click="saveItem(item)">
                  保存
                </el-button>
                <el-button type="danger"  style="margin-left: 10px" @click="deleteItem(itemIndex)">删除</el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <company-select ref="selectCom" @select="onSelectCom"></company-select>
  </el-dialog>
</template>

<script>
  import CompanySelect from "@/views/company/CompanySelect.vue";

  export default {
    components: {CompanySelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        model: {
          id: "",
          name: "",
          params: [],
          doc: "",
        },
        items: [],
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        this.contentLoading = true;
        this.$http
          .get("integrations/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
            this.getItemData();
          });
      },
      getItemData() {
        this.contentLoading = true;
        this.$http
          .get("integrationItems?integrationId=" + this.model.id)
          .then(({data}) => {
            this.contentLoading = false;
            this.items = data || [];
            console.log(this.items);
          });
      },
      onSelectCom(row, item) {
        item.companyId = row.id;
        item.companyName = row.name;
        item.tenantName = row.tenantName;
      },
      addItem() {
        let params = {};
        this.model.params.forEach(key => {
          params[key] = "";
        });
        let item = {
          vueKey: this.$util.genKey(),
          integrationId: this.model.id,
          companyId: "",
          companyName: "",
          tenantName: "",
          params: params,
        };
        this.items.push(item);
      },
      deleteItem(index) {
        let itemId = this.items[index].id;
        if (itemId) {
          this.$confirm("确定删除吗?", "提示", {type: "warning"}).then(() => {
            this.$http.delete("integrationItems", itemId).then(() => {
              this.$message.success("删除成功");
              this.getItemData();
            });
          });
        } else {
          this.items.splice(index, 1);
        }
      },
      saveItem(item) {
        this.$http.save("integrationItems", item).then(() => {
          this.$message.success("保存成功");
          this.getItemData();
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.form {
  margin-top: 5px;
}

.item {
  flex: 1;
  background-color: #fcfcfc;
  padding: 20px 15px 10px 10px;
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  margin-right: 5px;
}
</style>
