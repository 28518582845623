<template>
  <div>
    <el-upload
      class="upload"
      name="file"
      style="display: inline-block"
      action=""
      :accept="accept"
      :show-file-list="false"
      :http-request="uploadHttp"
      :before-upload="beforeUpload">
      <el-button type="primary" icon="el-icon-upload2" :loading="uploadLoading">{{uploadProgress}}</el-button>
    </el-upload>
    <a v-if="value" target="_blank" :href="value" style="margin-left: 10px">{{filename}}</a>
    <div style="font-size: 12px">{{$l("camera.supportExtension","支持扩展名")}}：{{accept}}</div>
  </div>
</template>
<script>
  import ossUtil from "@/util/ossUtil";

  export default {
    props: {
      value: {
        type: String,
      },
      filename: {
        type: String,
      },
      accept: {
        type: String,
        require: true,
      },
      maxSize: {
        type: Number,
        default: 10000,
      },
    },
    data() {
      return {
        uploadLoading: false,
        uploadProgress: this.$l("camera.clickToUpload","点击上传"),
      };
    },
    methods: {
      uploadHttp({file}) {
        ossUtil.upload(file, false, false, this.handleUploadProgress).then(res => {
          this.uploadLoading = false;
          this.$emit("file", file);
          this.$emit("input", res.url);
          this.$emit("update:filename", res.filename);
          this.uploadProgress = "上传成功";
          this.$message.success("上传成功，点击保存后生效");
        }).catch(error => {
          this.uploadLoading = false;
          this.uploadProgress = "上传失败";
          this.$message.error("上传失败：" + error);
        });
      },
      beforeUpload(file) {
        let fileName = file.name;
        let lastName = fileName.endsWith("tar.gz") ? ".tar.gz" : ("." + fileName.split(".").pop());
        if (!this.accept.split(",").includes(lastName.toLowerCase())) {
          this.$message.error("不支持的文件类型");
          return false;
        }
        let size = file.size / 1024 / 1024;
        if (size > this.maxSize) {
          this.$message.error(`文件大小不能超过${this.maxSize}MB!`);
          return false;
        }
        this.uploadLoading = true;
        this.uploadProgress = "正在上传 0.00%";
        return true;
      },
      handleUploadProgress(p) {
        this.uploadProgress = "正在上传 " + (p * 100).toFixed(2) + "%";
      },
    },
  };
</script>
<style lang="scss" scoped>
.file {
  margin-left: 10px;
  font-size: 14px;
}
</style>
